import { AiTwotoneStar } from 'react-icons/ai'
import styled from 'styled-components'

const StarBadge = () => {
  return (
    <Badge>
      <BadgeTop />
      <BadgeShadow />
      <AiTwotoneStar
        size={'2em'}
        style={{
          width: '100%',
          height: '87%',
          position: 'absolute',
          zIndex: 2,
          display: 'block',
          fontSize: '20px',
          color: '#fff',
          padding: '2px 8px 7px 8.5px'
        }}></AiTwotoneStar>
    </Badge>
  )
}

const BadgeTop = styled.div`
  position: relative;
  background: #ff8888;
  height: 3px;
  width: 36px;
  margin-bottom: -1px;
  z-index: 1;
`

const BadgeShadow = styled.div`
  position: absolute;
  z-index: 1;
  display: block;
  clip-path: polygon(100% 0, 100% 75%, 50% 100%, 0 75%, 0 0);
  // background: rgba(0, 0, 0, 0.5);
  height: 34px;
  width: 36px;
  background: #e01a1a;
`
const Badge = styled.div`
  position: absolute;
  right: 20px;
  width: 35px;
  height: 35px;
  top: -4px;
`

export default StarBadge
