export default function Description() {
  return (
    <section className="relative text-center py-[3rem] px-[2rem]">
      <div className="w-full px-[15px]">
        <div className="flex flex-col items-center">
          <h5 className="text-[2.6rem] tracking-[0.1rem] font-[Arial] font-medium uppercase mb-[1rem] text-[#444] leading-[1.2]">
            DRIVERS JOB CHOICE. YOUR JOB - YOUR CHOICE!
          </h5>
          <p className="max-w-[60rem] text-[1.3rem] text-[#444]">
            DriversJobChoice.com wants you to take control of your own
            employment opportunities! We bring you the best offers from top
            trucking companies across the nation. You respond to those offers
            based on what they are willing to offer you!
          </p>
          <p className="max-w-[60rem] text-[1.3rem] text-[#444]">
            If you’re considering a new driving job or just exploring the
            available opportunities, there is no better place than
            DriversJobChoice.com to find the most up-to-date driving
            opportunities available in today’s job market. From all the staff at
            DriversJobChoice.com wishing you the very best! Stay safe as you
            “Keep America Moving”!
          </p>
        </div>
      </div>
    </section>
  )
}
