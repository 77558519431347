import { useMediaQuery } from '@mui/material'
import Link from 'next/link'
import FeaturesList from '../features-list/FeaturesList'
import classes from './Features.module.scss'

export default function Features({
  featuredCompanies
}: {
  featuredCompanies: any[]
}) {
  const biggerScreen = useMediaQuery('(min-width: 993px)')

  if (biggerScreen) {
    return (
      <section className={`${classes.features}`}>
        <div className={`${classes.ctaBlock}`}>
          <div className={`${classes.yellowBlock}`}>Yellow Block</div>
          <div className={`${classes.blueBlock} flex flex-col`}>
            <div className={`${classes.container} flex flex-col`}>
              <h2>FEATURED COMPANIES</h2>
              <div>
                <div className={`${classes.redArrowWrapper}`}>
                  <div className={`${classes.arrowShadow}`}></div>
                  <Link
                    href="/trucking-companies"
                    className={`${classes.btn} ${classes.arrowButton}`}>
                    SEE ALL COMPANIES
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${classes.featuredList}`}>
          <div className={`${classes.containerFluid}`}>
            <div className={`${classes.row}`}>
              <div className={`col-sm-12 px-0`}>
                <FeaturesList companies={featuredCompanies} />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  return (
    <section className={`${classes.features}`}>
      <div className={`${classes.featuresMobile}`}>
        <div className={`${classes.featuresMobile__text}`}>
          <h2>FEATURED COMPANIES</h2>
        </div>
      </div>
      <div className={`${classes.featuredList}`}>
        <div className={`${classes.containerFluid}`}>
          <div className={`${classes.row}`}>
            <div className={`col-sm-12 px-0`}>
              <FeaturesList companies={featuredCompanies} />
            </div>
          </div>
        </div>
      </div>
      <div className={`${classes.featuresMobile}`}>
        <div className={`${classes.featuresMobile__button}`}>
          <div className={`${classes.redArrowWrapper}`}>
            <div className={`${classes.arrowShadow}`}></div>
            <Link
              href="/trucking-companies"
              className={`${classes.btn} ${classes.arrowButton}`}>
              SEE ALL COMPANIES
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
