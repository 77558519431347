import Head from 'next/head'
import { DOMAIN, FB_APP_ID } from '../../lib/Utils'

const title = 'Local, Regional & OTR Truck Driver Jobs | Driversjobchoice.com'
const description =
  'High Paying, Local, Regional & OTR Truck Driver Jobs. Your Job, Your Choice! Drivers Job Choice.'

const HomePageHead = () => {
  return (
    <Head>
      <meta name="viewport" content="initial-scale=1, width=device-width" />
      <link rel="icon" href="/favicon.ico" />
      <title>{title}</title>

      <meta name="twitter:card" content="summary" key="twitterCard"></meta>
      <meta property="og:url" content={DOMAIN} key="ogUrl"></meta>
      <meta property="og:title" content={title} key="ogTitle" />

      <meta name="description" content={description} key="description"></meta>
      <meta
        property="og:description"
        content={description}
        key="ogDescription"></meta>
      <meta
        property="og:image"
        content={`${DOMAIN}/assets/img/company/default.webp`}
        key="ogImage"></meta>

      <meta property="og:type" content="website" key="ogType"></meta>
      <meta property="fb:app_id" content={FB_APP_ID} key="fbAppId"></meta>
      <meta
        property="keywords"
        content="truck driver jobs, truck driving jobs, driver jobs, driving jobs, cdl jobs, owner operator jobs, trucking, trucking jobs, local driver jobs, OTR driver jobs, trucks, carriers, trucking companies, truck carriers"
        key="keywords"></meta>
    </Head>
  )
}

export default HomePageHead
