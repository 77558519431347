import Image from 'next/image'
import TruckerPic from '../../../public/assets/img/Hero-Trucker.webp'
import Link from 'next/link'
import { Button } from '@mui/material'
import classes from './Hero.module.scss'

export default function Hero() {
  return (
    <section className={classes.mainHero}>
      <div className={classes.mainHero__inner}>
        <div className={classes.leftCol}>
          <div className={classes.truckerImg} style={{ width: '100%' }}>
            <Image
              style={{
                maxWidth: 'inherit',
                bottom: 0,
                height: '100% !important',
                width: 'auto',
                position: 'relative'
              }}
              alt="hero pic"
              height={650}
              priority={true}
              src={TruckerPic}
            />
          </div>
        </div>
        <div className={classes.rightCol}>
          <h2 className={classes.mainTitle}>
            YOUR JOB! <br />
            YOUR CHOICE!
          </h2>
          <h1 className={classes.secondaryTitle}>
            OTR, REGIONAL & DEDICATED TRUCK DRIVER JOBS
          </h1>
          <div className={classes.greenWrapper}>
            <div className={classes.greenShadow}></div>
            <Button>
              <Link href="/truck-driving-jobs" className={classes.greenButton}>
                SEARCH DRIVER JOBS
              </Link>
            </Button>
          </div>
        </div>
      </div>
      <p>DRIVERSJOBCHOICE.COM</p>
    </section>
  )
}
