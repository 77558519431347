import { useCallback, useEffect, useRef, useState } from 'react'
import CustomizedModal from '../modals/CustomizedModal'
import SuccessButton from '../ui/SuccessButton'
import { useMediaQuery } from '@mui/material'
import classes from './VideoPlayer.module.scss'

interface Props {
  link?: string
  linkText?: string
  videoUrl: string
}
const VideoPlayer = ({ link, linkText, videoUrl }: Props) => {
  const [visible, setVisible] = useState(false)
  const youtubePlayerInstance = useRef<YT.Player>()

  const smallScreen = useMediaQuery('(max-width: 959.98px)')

  const playVideo = (videoId: string) => {
    youtubePlayerInstance.current = new YT.Player('hytPlayer', {
      videoId,
      width: '100%',
      height: '100%',
      playerVars: {
        rel: 0
      },

      events: {
        onReady: (event) => {
          event.target.playVideo()
        }
      }
    })
  }

  const startVideo = useCallback((videoId: string) => {
    if (!youtubePlayerInstance.current) {
      const tag = document.createElement('script')

      tag.src = 'https://www.youtube.com/iframe_api'
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag)
    } else {
      setTimeout(() => {
        playVideo(videoId!)
      }, 250)
    }

    const w = window as any
    w['onYouTubeIframeAPIReady'] = () => {
      playVideo(videoId)
    }
  }, [])

  useEffect(() => {
    if (videoUrl) {
      setVisible(true)
      startVideo(videoUrl)
    } else {
      setVisible(false)
    }
  }, [videoUrl, startVideo])

  return (
    <CustomizedModal
      classname={''}
      loadMask={false}
      footerCls="!flex !align-center !justify-center "
      contentCls="!p-0"
      hideFooter={!link}
      closeBtnColor="text-white"
      footer={
        link
          ? [
              <SuccessButton
                fullWidth={false}
                key="link"
                cls="!text-[18px] max-[768px]:leading-[.65rem]"
                asLink={link}>
                {/* View Job Post */}
                {linkText}
              </SuccessButton>
            ]
          : null
      }
      PaperProps={
        smallScreen
          ? {
              style: {
                width: '95%',
                height: 'auto',
                maxWidth: '780px',
                maxHeight: '90vh',
                backgroundColor: '#000'
              }
            }
          : {
              style: {
                width: '90%',
                height: 'auto',
                maxWidth: '780px',
                maxHeight: '90vh',
                backgroundColor: '#000'
              }
            }
      }
      open={visible}
      onCancel={() => setVisible(false)}>
      <div className={`${classes.hytPlayerWrap}`}>
        <div id="hytPlayer"></div>
      </div>
    </CustomizedModal>
  )
}

export default VideoPlayer
