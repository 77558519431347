import Image from 'next/legacy/image'
import { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'

import 'swiper/scss/autoplay'
import 'swiper/scss/scrollbar'
import classes from './FeaturesList.module.scss'
import StarBadge from '../../ui/card/StarBadge'
import { BsYoutube } from 'react-icons/bs'
import SwiperCore, { Autoplay, Scrollbar } from 'swiper'
import VideoPlayer from '../../video-player/VideoPlayer'

export default function FeaturesList({ companies = [] }: { companies: any[] }) {
  const [videoData, setVideoData] = useState({
    videoData: {
      videoId: '',
      link: ''
    },
    slug: ''
  })

  SwiperCore.use([Scrollbar, Autoplay])

  const breakPoints = {
    0: {
      slidesPerView: 1,
      spaceBetween: 10
    },
    480: {
      slidesPerView: 2,
      spaceBetween: 10
    },
    640: {
      slidesPerView: 3,
      spaceBetween: 10
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 10
    },
    1080: {
      slidesPerView: 4,
      spaceBetween: 10
    },
    1450: {
      slidesPerView: 5,
      spaceBetween: 10
    },
    2048: {
      slidesPerView: 6,
      spaceBetween: 10
    }
  }

  return (
    <div className={`${classes.featuredJobs} px-[10px]`}>
      <div className="slider-stage-outer">
        {companies.length && (
          <Swiper
            spaceBetween={10}
            autoplay={true}
            slidesPerView={5}
            loop={true}
            autoHeight={true}
            breakpoints={breakPoints}>
            {companies.map((company, i) => {
              return (
                <SwiperSlide key={company._id + i}>
                  <div
                    className={classes.item}
                    onClick={() =>
                      setVideoData({
                        slug: company.slug,
                        videoData: company.videoData
                      })
                    }>
                    <StarBadge />

                    <div className={classes.jobImg}>
                      <Image
                        alt={`${company.name} Image`}
                        layout="responsive"
                        objectPosition="top"
                        objectFit="contain"
                        width={1280}
                        height={720}
                        src={company.videoData.src}
                      />
                    </div>
                    <div className={`${classes.youtubeIconWrp}`}>
                      <BsYoutube className={`${classes.youtubeIcon}`} />
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}
      </div>
      <VideoPlayer
        link={`/truck-driving-jobs/${videoData.slug}`}
        linkText="View Job Post"
        videoUrl={videoData.videoData.videoId}
      />
    </div>
  )
}
