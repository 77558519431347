import Image from 'next/legacy/image'
import { useEffect, useState } from 'react'
import classes from './Testimony.module.scss'

export interface ITestemony {
  imagePath: string
  testimonyAuthor: string
  testimonyQuote: string
}

export default function TestimonyList() {
  const [currentTestimonies, setCurrentTestimonies] = useState<ITestemony[]>([])
  useEffect(() => {
    setCurrentTestimonies(
      [...testimonies].sort(() => Math.random() - 0.5).slice(0, 3)
    )
  }, [])

  return (
    <div
      className={`flex justify-around	flex-row my-[5rem] mx-[0px] relative ${classes.testimonyContainer}`}
      // style={{ margin: '5rem 0' }}
    >
      {currentTestimonies.map((t, i) => {
        return (
          <div
            key={i}
            className={`relative flex justify-center mx-[1.5rem] w-[20rem] h-[20rem] z-[2] items-center overflow-hidden ${classes.hoverBox}`}>
            <Image
              alt="Truck Driver Img"
              src={t.imagePath}
              layout="fill"
              objectFit="cover"
            />
            <div
              className={`absolute flex items-center w-full h-full text-white bg-[#0009] opacity-0  hover:opacity-100 ${classes.top}`}>
              <div className="font-arial text-left absolute flex flex-col justify-center items-center p-[1rem]">
                <p className={`text-[1rem] italic ${classes.quote}`}>
                  {t.testimonyQuote}
                </p>
                <span
                  className={`text-[0.9rem] text-right w-full ${classes.author}`}>
                  {t.testimonyAuthor}
                </span>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const testimonies: ITestemony[] = [
  {
    imagePath: '/assets/img/testimonies/driver1.webp',
    testimonyAuthor: 'Johnny McDaniel',
    testimonyQuote: `"I just want everyone to know. This really works. Trucking companies will call you! Have you  feeling like the first-round draft pick! If you want a driving job, regardless of your situation,  do this."`
  },
  {
    imagePath: '/assets/img/testimonies/driver2.webp',
    testimonyAuthor: 'Shannon Gregory',
    testimonyQuote: `"I got 5 calls the first day after I filled out the application!! Got my class A in July! Got a local  dedicated route and I am home every day!"`
  },
  {
    imagePath: '/assets/img/testimonies/driver3.webp',
    testimonyAuthor: 'Tim Shuttles',
    testimonyQuote: `"I got the job I wanted from this site. I came up with a $5000 sign on bonus plus $500  transition bonus. I have 1yr experience. They put me in a room for a 3-day orientation and  was on the road by that third day solo."`
  },
  {
    imagePath: '/assets/img/testimonies/driver4.webp',
    testimonyAuthor: 'Sam Phillips',
    testimonyQuote: `"You helped me get the best trucking job I've had in twenty five years of driving. It's not all about money. It's about meeting your needs and wants. Thanks."`
  },
  {
    imagePath: '/assets/img/testimonies/driver5.webp',
    testimonyAuthor: 'John Roberts',
    testimonyQuote: `"Worked great for me. Made it easy for me to pick and choose."`
  }
]
