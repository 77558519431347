import { useContext, useState } from 'react'
import LeadContext from '../../../contexts/LeadContext'
import LeadAcquisitionModal from '../../modals/LeadAcquisitionModal'
import classes from './Testimony.module.scss'
import TestimonyList from './TestimonyList'

export default function Testimony() {
  const [showLeadAcquisition, setShowLeadAcquisition] = useState(false)
  const { setLeadFormStaticData } = useContext(LeadContext)
  const onApply = () => {
    setLeadFormStaticData({})
    setShowLeadAcquisition(true)
  }

  return (
    <>
      <section
        className={`${classes.section} flex flex-col items-center bg-[url('../public/assets/img/Testimonials-Background.webp')] relative bg-cover bg-left-top bg-no-repeat max-h-max`}>
        <div className={`relative z-[2] top-[-0.8rem] ${classes.redRibbon}`}>
          <div
            className={`absolute  w-full h-[95%] mt-[0.7rem] bg-[#00000080] ${classes.clipShadow}`}></div>
          <div
            className={`relative bg-[#ff8888] h-[0.6rem] w-full bottom-[-0.3px] ${classes.clipTop}`}></div>
          <div
            className={`text-center  w-[34rem] px-[0.2rem] py-[1.2rem] bg-[#e01a1a] text-white ${classes.clipText} ${classes.text}`}>
            <h3
              className={`text-[1.75rem] leading-[1.2] mb-[0.5rem] font-arial-black tracking-[0.2rem] uppercase text-inherit ${classes.h3}`}>
              Helping Drivers Across America
            </h3>
          </div>
        </div>
        <TestimonyList />
        <div className="mb-[4rem] mt-[10px] relative">
          <div className="absolute bg-[#000c] h-[98%] mt-[8px] w-[96%] ml-[2%] rounded"></div>
          <button
            onClick={() => onApply()}
            className={`relative py-[6px] px-[12px] tracking-[2px] bg-gradient-to-r from-[#207f0e] to-[#26a20f] rounded border border-solid border-[#2fc513] text-white uppercase shadow-none font-bold text-[25px] z-[10] transition transform hover:translate-y-1 ${classes.greenButton}`}>
            Apply Now!
          </button>
        </div>
      </section>
      {showLeadAcquisition && (
        <LeadAcquisitionModal
          open={showLeadAcquisition}
          handleClose={setShowLeadAcquisition}
        />
      )}
    </>
  )
}
