import Link from 'next/link'
import { ReactNode } from 'react'
import cn from 'clsx'

interface Props {
  children: ReactNode
  asLink?: string
  onClick?: () => void
  fullWidth?: boolean
  cls?: string
}

const className =
  ' h-full text-[18px] font-semibold text-center inline-block px-6 py-2.5 bg-green-600 text-white text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out'

const SuccessButton = ({
  children,
  onClick,
  asLink,
  fullWidth = true,
  cls = ''
}: Props) => {
  if (asLink) {
    return (
      <Link
        href={asLink}
        className={cn(className, cls, {
          'w-full': fullWidth
        })}>
        {children}
      </Link>
    )
  }
  return (
    <button
      type="button"
      className={cn(className, cls, {
        'w-full': fullWidth
      })}
      onClick={onClick}>
      {children}
    </button>
  )
}

export default SuccessButton
